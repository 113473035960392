import { Col, Input, Row, Select, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Headers from './Headers';
import Query from './Query';
import Body from './Body';
import { Endpoint, EndpointMethod } from 'entities/Entities';
import { validatePath } from 'utils/function';
import '../../index.scss';
const { TabPane } = Tabs;
const { Option } = Select;

interface ApiDefinitionProps {
  endpoint: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint>>;
}

const ByGET: React.FC<ApiDefinitionProps> = React.memo(({ setEndpoint, endpoint }) => {
  return (
    <Tabs defaultActiveKey="headers" onChange={() => {}} className="tab_definition">
      <TabPane tab="Headers" key="headers">
        <Headers setEndpoint={setEndpoint} endpoint={endpoint} />
      </TabPane>
      <TabPane tab="Query" key="query">
        <Query setEndpoint={setEndpoint} endpoint={endpoint} />
      </TabPane>
    </Tabs>
  );
});

const ByPOST: React.FC<ApiDefinitionProps> = React.memo(({ setEndpoint, endpoint }) => {
  return (
    <>
      <Tabs defaultActiveKey="headers" onChange={() => {}} className="" style={{ width: '100%' }}>
        <TabPane tab="Headers" key="headers">
          <Headers setEndpoint={setEndpoint} endpoint={endpoint} />
        </TabPane>
        <TabPane tab="Query" key="query">
          <Query setEndpoint={setEndpoint} endpoint={endpoint} />
        </TabPane>
        <TabPane tab="Body" key="body">
          <Body setEndpoint={setEndpoint} endpoint={endpoint} />
        </TabPane>
      </Tabs>
    </>
  );
});

enum RefIdx {
  Path,
}
const ApiDefinition: React.FC<ApiDefinitionProps> = React.memo(({ setEndpoint, endpoint }) => {
  const [method, setMethod] = useState(endpoint.method || EndpointMethod.GET);
  const [pathValid, setValid] = useState(true);
  const refs = React.useRef<any>([]);

  useEffect(() => {
    setEndpoint({
      ...endpoint,
      method,
    });
  }, []);

  const onBlur = (idx: RefIdx) => {
    const val = refs.current[idx]?.input?.value || '';
    if (!validatePath(val)) {
      setValid(false);
      return;
    }
    switch (idx) {
      case RefIdx.Path:
        setValid(true);
        setEndpoint({
          ...endpoint,
          path: val,
        });
        return;
    }
  };

  const onChangeMethod = (val: EndpointMethod) => {
    setMethod(val);
    setEndpoint({
      ...endpoint,
      method: val,
    });
  };

  return (
    <>
      <Row className="input_item">
        <Col span={2}>
          <Select defaultValue={method} bordered={false} className="select_method" onChange={onChangeMethod}>
            {Object.keys(EndpointMethod).map((m: string) => (
              <Option key={m} value={m}>
                {m}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={22}>
          <Input
            ref={(el: any) => {
              refs.current[RefIdx.Path] = el;
            }}
            onBlur={() => onBlur(RefIdx.Path)}
            placeholder="/detect-image/{id}"
            defaultValue={endpoint.path}
          />
          {pathValid ? null : <span style={{ color: '#ff4d4f' }}>Path is not valid</span>}
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        {method.toUpperCase() == EndpointMethod.GET.toUpperCase() && (
          <ByGET setEndpoint={setEndpoint} endpoint={endpoint} />
        )}
        {method.toUpperCase() == EndpointMethod.POST.toUpperCase() && (
          <ByPOST setEndpoint={setEndpoint} endpoint={endpoint} />
        )}
      </Row>
    </>
  );
});

export default ApiDefinition;
