import { Button, Checkbox, Input, Popconfirm, Select, Table } from "antd";
import { BodyParam, ContentType, Endpoint, EndpointParam, EndpointParams, EndpointType } from "entities/Entities";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { TableConfigs } from "../../../TableConfigs";
const { Option } = Select;

interface BodyProps {
  endpoint: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint>>;
}

type DataSource = EndpointParam;

const ByFormData: React.FC<BodyProps> = ({endpoint, setEndpoint}) => {
  const prms: EndpointParams = !isEmpty(endpoint.params) ? {...endpoint.params} : {};
  const body: BodyParam = prms.body ? prms.body : {} as BodyParam;
  const [dataSource, setDataSource] = useState<EndpointParam[]>(body.formData || []);
  const refs = React.useRef<any>([]);
  const tableConfigs = TableConfigs();
  const { EditableCell, EditableRow } = tableConfigs;
  useEffect(() => {
    setEndpoint({
      ...endpoint, 
      params: {
        ...prms,
        body: {
          ...body,
          formData: dataSource
        }
      }
    });
  }, [dataSource])

  const handleChangeRequired = (e: any, record: DataSource) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, isRequired: e.target.checked };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const handleChangeType = (val: EndpointType, record: DataSource) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, type: val };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const handleBlurInput = (idx: string, record: DataSource) => {
    const val = refs.current[idx]?.input?.value || "";
    if (!val) return;

    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, name: val };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      render: (_: any, record: DataSource) => (
        <Input 
          ref={(el: any) => {refs.current[`name${record.id}`] = el}}
          onBlur={() => handleBlurInput(`name${record.id}`, record)}
          defaultValue={record.name}
        />
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '30%',
      render: (_: any, record: DataSource) => (
        <Select
          defaultValue={EndpointType.string}
          bordered={false}
          className="select_method"
          onChange={(val: EndpointType)=>handleChangeType(val, record)}
          value={record.type}
        >
          {Object.keys(EndpointType).map((m: string) => (
            <Option key={m} value={m}>
              {m}
            </Option>
          ))}
      </Select>
      )
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      render: (_: any, record: DataSource) => (
        <Checkbox onChange={(e) => handleChangeRequired(e, record)} checked={record.isRequired}/>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: DataSource) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataSource = {
      key: dataSource.length,
      id: dataSource.length,
      name: "",
      type: EndpointType.string,
      isRequired: true,
    };
    setDataSource([...dataSource, newData]);
  };

  const handleDelete = (key: any) => {
    setDataSource([...dataSource.filter((d: any) => d.key !== key)]);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
          className="btn"
        >
          Add a param
        </Button>

        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
    </>
  );
} 

const ByJson: React.FC<BodyProps> = ({setEndpoint, endpoint}) => {
  const prms: EndpointParams = !isEmpty(endpoint.params) ? {...endpoint.params} : {};
  const body: BodyParam = prms.body ? prms.body : {} as BodyParam;
  const refs = React.useRef<any>([]);

  const onBlur = (idx: string) => {
    const val = refs.current[idx]?.resizableTextArea?.props?.value || "";
    setEndpoint({
      ...endpoint,
      params: {
        ...prms,
        body: {
          ...body,
          jsonData: val
        }
      }
    })
  }

  return (
    <div className="by-json">
      <div className="as-label">Example Data <span className="as-require">*</span></div>
      <Input.TextArea
        ref={(el: any) => {refs.current['json-data'] = el}}
        onBlur={() => onBlur('json-data')}
        defaultValue={body?.jsonData || ""}
        style={{marginTop: "10px"}}
        rows={8}
        placeholder={`{
  key1: "value1",
  key2: "value2"
}
          `
        }
      />
    </div>
  );
}

const ByText: React.FC<BodyProps> = ({setEndpoint, endpoint}) => {
  const prms: EndpointParams = !isEmpty(endpoint.params) ? {...endpoint.params} : {};
  const body: BodyParam = prms.body ? prms.body : {} as BodyParam;
  const refs = React.useRef<any>([]);

  const onBlur = (idx: string) => {
    const val = refs.current[idx]?.resizableTextArea?.props?.value || "";
    setEndpoint({
      ...endpoint,
      params: {
        ...prms,
        body: {
          ...body,
          textData: val
        }
      }
    })
  }

  return (
    <div className="by-json">
      <div className="as-label">Example Data <span className="as-require">*</span></div>
      <Input.TextArea
        ref={(el: any) => {refs.current['json-data'] = el}}
        onBlur={() => onBlur('json-data')}
        defaultValue={body?.textData || ""}
        style={{marginTop: "10px"}}
        rows={8}
        placeholder={`'{
  "key1": "value1",
  "key2": "value2"
}'
          `
        }
      />
    </div>
  );
}

const Body: React.FC<BodyProps> = React.memo(({ setEndpoint, endpoint }) => {
  const prms: EndpointParams = !isEmpty(endpoint.params) ? {...endpoint.params} : {};
  const body: BodyParam = prms.body ? prms.body : {} as BodyParam;
  const [contentType, setContentType] = useState<ContentType>(prms.body?.contentType || ContentType['application/json']);

  useEffect(() => {
    setEndpoint({
      ...endpoint, 
      params: {
        ...prms,
        body: {
          ...body,
          contentType
        }
      }
    });
  }, []);

  const onChangeContentType = (type: ContentType) => {
    setContentType(type);
    setEndpoint({
      ...endpoint, 
      params: {
        ...prms,
        body: {
          ...body,
          contentType: type
        }
      }
    });
  }

  return (
    <>
      <div className="as-label">Content type: </div>
      <Select
          defaultValue={contentType}
          bordered={false}
          className="select_content-type"
          onChange={onChangeContentType}
        >
          {Object.keys(ContentType).map((ct: string) => (
            <Option key={ct} value={ct}>
              {ct}
            </Option>
          ))}
      </Select>
      {
        contentType.toUpperCase() == ContentType['application/json'].toUpperCase() && 
        <ByJson setEndpoint={setEndpoint} endpoint={endpoint} />
      }
      {
        contentType.toUpperCase() == ContentType['multipart/form-data'].toUpperCase() && 
        <ByFormData setEndpoint={setEndpoint} endpoint={endpoint} />
      }
      {
        contentType.toUpperCase() == ContentType["text/plain"].toUpperCase() && 
        <ByText setEndpoint={setEndpoint} endpoint={endpoint} />
      }
    </>
  );
})

export default Body;
