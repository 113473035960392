import { Button, Col, Form, Input, Image, message, Modal, Row, Typography, Select } from 'antd';
import { UploadEntity, ServiceEntity } from 'entities/Entities';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ReactComponent as TrashIC } from 'assets/icons/trash_empty.svg';
import Dragger from 'antd/lib/upload/Dragger';
import { ReactComponent as UploadIC } from 'assets/icons/upload.svg';
import { addService } from '../hooks';
import { uploader, useFetchCategory } from 'services/commons';
import { useQuery } from 'react-query';
import './index.scss';
import { createFileData, getBase64 } from 'utils/function';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface AddServiceModalProps {
  handleCancel: () => void;
}

const AddServiceModal: React.FC<AddServiceModalProps> = ({ handleCancel }) => {
  const { data: categories } = useQuery<any>(['categories'], useFetchCategory);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [preview, setPreview] = useState<any>();
  const [thumb, setThumb] = useState<any>();
  const [loading, setLoading] = useState<any>();

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: true,
    onRemove: () => {
      setThumb(null);
      setPreview(null);
    },
    beforeUpload: (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest: (file: any) => {
      onUploadThumb();
    },
    onChange: async (info: any) => {
      setThumb(info);
      const previewImg = await getBase64(info.fileList[0].originFileObj ?? info.file.originFileObj);
      setPreview(previewImg);
    },
  };

  const uploaderMutation = useMutation(uploader, {
    onSuccess: async (data: UploadEntity) => {
      return data;
    },
    onError: (err: any) => {
      message.error(err.message);
      handleCancel();
    },
  });

  const onUploadThumb = async () => {
    if (thumb && thumb !== null) {
      const FileList = thumb['fileList']?.map((file: any) => {
        return file.originFileObj;
      });

      const formDataFiles = createFileData(FileList);
      const response = await uploaderMutation.mutateAsync(formDataFiles);
      return response.link;
    }
    return null;
  };

  const addServiceMutation = useMutation(addService, {
    onSuccess: (data: ServiceEntity) => {
      queryClient.refetchQueries('myServices', { active: true, exact: true });
      setLoading(false);
      message.success('Service added successfully');
      handleCancel();
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err.message);
    },
  });

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const { name, description, category } = values;
      const thumbnail = await onUploadThumb();
      await addServiceMutation.mutateAsync({ name, description, thumbnail, category_id: category });
    } catch (err: any) {
      console.log(err);
    }
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  return (
    <Modal title="Add Service" visible={true} onCancel={handleCancel} footer={null} width={1000} maskClosable={false}>
      <div className="add-service-overview">
        <div className="upload-thumb">
          <Text className="as-title">UPLOAD THUMBNAIL</Text>
          {preview ? (
            <div className="as-image-container">
              <Image
                className="as-image"
                src={preview ?? 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
              />
              <button
                className="as-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setThumb(null);
                  setPreview(null);
                }}
              >
                <TrashIC width={30} height={30} />
              </button>
            </div>
          ) : (
            <div className="drag-zone">
              <Dragger style={{ marginTop: 15, borderColor: '#DD4AE0', borderRadius: 8, width: '200px' }} {...props}>
                <p className="ant-upload-drag-icon">
                  <UploadIC />
                </p>
                <p className="ant-upload-hint">
                  Drop your image here, or <Text style={{ color: '#DD4AE0' }}>Browse</Text>
                </p>
              </Dragger>
            </div>
          )}
        </div>
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="as-content">
            <Row className="as-row">
              <Col span={12}>
                <p className="as-label">
                  Name <span className="as-require">*</span>
                </p>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input name service!',
                    },
                  ]}
                >
                  <Input className="as-input small" placeholder="Eg. Super Art Generative Model" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="as-row">
              <Col span={12}>
                <p className="as-label">
                  Category <span className="as-require">*</span>
                </p>
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select category!',
                    },
                  ]}
                >
                  <Select placeholder="Category" bordered={false} className="select-package" onChange={() => {}}>
                    {categories?.map((cate: any) => (
                      <Option key={cate.id} value={cate.id}>
                        {cate.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="as-label">
                  Description <span className="as-require">*</span>
                </p>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input description service!',
                    },
                  ]}
                >
                  <TextArea
                    className={'as-input'}
                    style={{ minHeight: 150 }}
                    placeholder={`Eg. Super Art Generative Model`}
                    allowClear
                    onChange={() => {}}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="btn-group">
            <Form.Item>
              <Button className="btn" htmlType="submit" loading={loading}>
                <Text className=""> Add </Text>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddServiceModal;
