export interface UserEntity {
  id: number;
  publicAddress: string;
  avatar?: string;
  username?: string;
}

export interface ServiceEntity {
  id: number;
  name: string;
  description: string;
  on_sale: boolean;
  thumbnail?: string;
  user: UserEntity;
  packages: PackageEntity[];
  is_owned?: boolean;
  bought_requests?: RequestEntity[];
  demo_request?: DemoRequestEntity;
  rating?: RatingEntity;
  config?: ServiceConfig;
  endpoints?: Endpoint[];
  category: CategoryEntity;
  status : ServiceStatus
}

export interface UploadEntity {
  link: string;

}

export interface PackageEntity {
  id: number;
  number_requests: number;
  on_sale: boolean;
  per_price: number | string;
  service_id: number;
  validity_period?: number; 
  created_at?: string;
  updated_at?: string;
}

export interface RequestEntity {
  id: number;
  customer_id: number;
  number_packages: number;
  number_requests: number;
  success_packages: number;
  success_requests: number;
  expired_at?: string;
  created_at: string;
  updated_at: string;
  package_id: number;
  package: PackageEntity & {
    service: ServiceEntity & {
      user: UserEntity
    }
  };
}

export interface OfferingEntity {
  id: number;
  customer_id: number;
  package_id: number;
	amount: number | string;
  created_at?: Date;
  customer: UserEntity;
  package: PackageEntity;
}


export interface ClaimInfoEntity {
  claimable: boolean;
  claimable_amount: number | string;
  claimed: number | string;
  customer: string; //
  number_requests: number | string;
  package_id: number | string;
  per_price: number | string;
  success_requests: number | string;
}
export interface ClaimEntity {
  claim_info: ClaimInfoEntity
}

export interface DemoRequestEntity {
  service_id: number;
  customer_id: number;
  free_demo: number;
  success_demo: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface PaginationEntity {
  page?: number;
  pageSize?: number;
  sortBy?: string[];
  filter?: Record<any, any>;
  keyword?: string;
}

export interface BalanceEntity {
  denom: string;
  amount: string;
}

export interface RatingEntity {
  count: number;
  average: number;
}

export interface ServiceConfig {
  id: number;
  base_url: string;
  token?: string;
}

export interface Endpoint {
  id?: number;
  service_id: number;
  name: string;
  description: string;
  instruction_link?: string;
  method: EndpointMethod;
  path: string;
  params?: any; //EndpointParams
}

export enum EndpointMethod {
  GET = "GET",
  POST = "POST",
}

export enum EndpointType {
  string = "string",
  number = "number",
  boolean = "boolean",
  date = "date",
}

export interface EndpointParams {
  headers?: HeaderParam[];
  query?: QueryParam[];
  body?: BodyParam,
}

export interface EndpointParam {
  id: number;
  name: string;
  type: EndpointType;
  isRequired: boolean;
  key?: number;
}

export interface HeaderParam extends EndpointParam {}
export interface QueryParam extends EndpointParam {}

export enum ContentType {
  'application/json' = "application/json",
  'multipart/form-data' = 'multipart/form-data',
  'text/plain' = 'text/plain',
}

export interface BodyParam {
  contentType: ContentType,
  formData?: any, //EndpointParam,
  jsonData?: string,
  textData?: string,
}

export interface CategoryEntity {
  id: number;
  name: string;
}

export enum ServiceStatus {
  Unverified,
  Pending,
  Verified,
}