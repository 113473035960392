import { message, Modal } from 'antd';
import { ServiceEntity } from 'entities/Entities';
import { withdrawService } from 'pages/ServiceManagement/hooks';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

/// Delete

interface WithdrawModalProps {
  handleCancel: () => void;
  service: ServiceEntity;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ handleCancel, service }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const withdrawMutation = useMutation(withdrawService, {
    onSuccess: (data: any) => {
      queryClient.refetchQueries(['service', { id: service.id, isAuthen: true }], { active: true, exact: true });
      message.success('Withdraw successfully!');
      setLoading(false);
      handleCancel();
    },
    onError: (error: any) => {
      message.error('Withdraw failed!');
      setLoading(false);
      handleCancel();
    },
  });

  const onFinish = async () => {
    if (!service.on_sale) return;
    setLoading(true);
    await withdrawMutation.mutate({
      service_id: service.id,
    });
  };

  return (
    <Modal
      title="Add package"
      visible={true}
      onOk={onFinish}
      onCancel={handleCancel}
      okText="Withdraw"
      confirmLoading={loading}
    >
      <p>Are you sure you want to withdraw all the packages of this service?</p>
    </Modal>
  );
};

export default WithdrawModal;
