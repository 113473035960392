import { Button, Space, Table } from 'antd';
import { Endpoint, ServiceEntity } from 'entities/Entities';
import React, { FunctionComponent, useState } from 'react';
import Definition from './Definition';
import ListEndpoints from './ListEndpoints';
import '../../index.scss';

interface EndpointsProps {
  service: ServiceEntity;
}

export enum ViewMode {
  ShowList,
  ShowDefinition,
}

//=====================================

const Endpoints: FunctionComponent<EndpointsProps> = ({ service }) => {
  const [viewMode, setViewMode] = useState(ViewMode.ShowList);
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint>();

  const onEdit = (ep: Endpoint) => {
    setViewMode(ViewMode.ShowDefinition);
    setSelectedEndpoint(ep);
  };

  const onCancel = () => {
    setSelectedEndpoint(undefined);
    setViewMode(ViewMode.ShowList);
  };

  return (
    <div className="endpoints">
      {viewMode == ViewMode.ShowList && (
        <>
          <Button className="btn btn_add-endpoint" onClick={() => setViewMode(ViewMode.ShowDefinition)}>
            Add Endpoint
          </Button>
          <ListEndpoints service={service} setViewMode={setViewMode} onEdit={onEdit} />
        </>
      )}
      {viewMode == ViewMode.ShowDefinition && (
        <Definition onCancel={onCancel} service={service} currentEndpoint={selectedEndpoint} />
      )}
    </div>
  );
};

export default Endpoints;
