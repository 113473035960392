import React, { useContext, useReducer } from 'react';

interface IViewState {
  view: string;
}

export enum ViewActionType {
  Change = 'Change',
}

const initialState: IViewState = {
  view: 'grid',
};

export const ViewContext = React.createContext<{
  state: IViewState;
  dispatch: (action: any) => void;
}>({
  state: initialState,
  dispatch: () => {},
});

const reducer = (state: IViewState, action: any): IViewState => {
  switch (action.type) {
    case ViewActionType.Change:
      return {
        ...state,
        view: action.payload.view,
      };
    default:
      return state;
  }
};

const ViewProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

export const useViewProvider = () => useContext(ViewContext) ?? {};

export default ViewProvider;
