import { Spin, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import Overview from './overview/Overview';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Configuration from './configuration/Configuration';
import PackageManagement from './package-management/PackageManagement';
import { useParams } from 'react-router';
import { isLoggedIn } from 'providers/AuthProvider';
import { ServiceEntity, ServiceStatus } from 'entities/Entities';
import { useFetchServiceDetail } from '../hooks';
import { useQuery } from 'react-query';
import './index.scss';
const { Step } = Steps;

interface MyServicesProps {}

enum StepsEnum {
  Overview,
  Configuration,
  PackageManagment,
}

export default React.memo(({}: MyServicesProps) => {
  const [step, setStep] = useState(StepsEnum.Overview);
  const [validConfig, setValidConfig] = useState(false);
  const params = useParams();
  const isAuthen = isLoggedIn();

  const { isLoading, data: service } = useQuery<ServiceEntity>(
    ['service', { id: +params.id!, isAuthen }],
    useFetchServiceDetail,
  );

  useEffect(() => {
    const valid = !!(
      service &&
      service.config &&
      service.config.base_url &&
      service.endpoints &&
      service.endpoints.length > 0
    );
    setValidConfig(valid);
  }, [service]);

  useEffect(() => {
    setStep(StepsEnum.Overview);
  }, [params?.id]);

  const onChange = (current: any) => {
    setStep(current);
  };

  return (
    <>
      {!service ? (
        <Spin className="spin" />
      ) : (
        <div className="my-services">
          <h3 className="service-title">{service.name}</h3>
          <p>
            <span className="status">
              {service.status === ServiceStatus.Unverified ? (
                <QuestionCircleOutlined className="ic-status" />
              ) : service.status === ServiceStatus.Pending ? (
                <ClockCircleOutlined className="ic-status pending" />
              ) : (
                <CheckCircleOutlined className="ic-status verified" />
              )}

              {service.status === ServiceStatus.Unverified ? (
                <Tooltip
                  placement="rightBottom"
                  title={'Your service needs to be verified before being able to be sold.'}
                >
                  <span className="status-txt">Unverified</span>
                </Tooltip>
              ) : service.status === ServiceStatus.Pending ? (
                <span className="status-txt pending">Pending</span>
              ) : (
                <span className="status-txt verified">Verified</span>
              )}
            </span>
          </p>
          <Steps type="navigation" size="small" current={step} onChange={onChange} className="site-navigation-steps">
            <Step status="finish" title="Service Detail" />
            <Step status="finish" title="Configuration" />
            <Step status={!validConfig ? 'wait' : 'finish'} title="Package management" disabled={!validConfig} />
          </Steps>
          {step === StepsEnum.Overview && <Overview service={service} />}
          {step === StepsEnum.Configuration && <Configuration service={service} />}
          {step === StepsEnum.PackageManagment && <PackageManagement service={service} />}
        </div>
      )}
    </>
  );
});
