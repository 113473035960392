import { ServiceConfig, Endpoint, ServiceEntity, PackageEntity } from "entities/Entities";
import API from "services/api/API";
import { isValidJson } from "utils/function";

export const addService = (data: any): Promise<ServiceEntity> => {
  try {
    return API.request({
        url: API.EndPoint().AddService,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as ServiceEntity);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}


export const useFetchServiceDetail = ({ queryKey }: any): Promise<ServiceEntity> => {
  const [_key, { id, isAuthen }] = queryKey;
  try {
    return API.request({
        url: API.EndPoint({ id }).ServiceDetail,
        method: 'GET',
        isAuthen
      })
      .then((res: any) => {
        const service: ServiceEntity = {
          ...res.data,
          endpoints: res.data?.endpoints?.map((ep: Endpoint) => ({ ...ep, params: isValidJson(ep.params) ? JSON.parse(ep.params) : {} })),
        }
        return service; 
      });
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const fetchMyServices = () => {
  try {
    return API.request({
        url: API.EndPoint().MyServices,
        method: 'GET', 
        isAuthen: true,
      })
      .then((res: any) => res.data?.data || []);
  } catch(err) {
    console.log(err);
    return Promise.resolve([]);
  }
}

export const updateService = (data: any): Promise<ServiceEntity> => {
  try {
    return API.request({
        url: API.EndPoint({ id: data.id }).UpdateService,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as ServiceEntity);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}


export const verifyService = (data: any): Promise<ServiceEntity> => {
  try {
    return API.request({
        url: API.EndPoint().VerifyService,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as ServiceEntity);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const changeServiceConfig = (data: any): Promise<ServiceConfig> => {
  try {
    return API.request({
        url: API.EndPoint().ChangeServiceConfig,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as ServiceConfig);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const changeEndpoint = (data: any): Promise<Endpoint> => {
  try {
    return API.request({
        url: API.EndPoint().ChangeEndpoint,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as Endpoint);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const addPackage = (data: any): Promise<PackageEntity> => {
  try {
    return API.request({
        url: API.EndPoint().AddPackage,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data as PackageEntity);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}


export const sellService = (data: any): Promise<any> => {
  try {
    return API.request({
        url: API.EndPoint().SellService,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const withdrawService = (data: any): Promise<any> => {
  try {
    return API.request({
        url: API.EndPoint().WithdrawService,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}