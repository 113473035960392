import { Button, Col, Form, InputNumber, message, Modal, Row } from "antd";
import { PackageEntity, ServiceEntity } from "entities/Entities";
import { addPackage } from "pages/ServiceManagement/hooks";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

// Delete
interface AddPackageModalProps {
  handleCancel: () => void;
  service: ServiceEntity;
}

const AddPackageModal: React.FC<AddPackageModalProps> = ({ handleCancel, service }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const addPackageMutation = useMutation(addPackage, {
    onSuccess: (data: PackageEntity) => {
      queryClient.refetchQueries(['service', { id: service.id, isAuthen: true }], { active: true, exact: true });
      message.success('Add package successfully!');
      setLoading(false);
      handleCancel();
    },
    onError: (error: any) => {
      message.error('Add package failed!');
      setLoading(false);
      handleCancel();
    },
  });

  const onFinish = async (values: any) => {
    setLoading(true);
    const { number_requests, per_price, validity_period } = values;
    await addPackageMutation.mutate({ number_requests, per_price, validity_period, service_id: service.id });
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  return (
    <Modal title="Add package" visible={true} onCancel={handleCancel} footer={null}>
      <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Form.Item
          name="number_requests"
          label="Number of requests"
          rules={[
            {
              type: 'number',
              required: true,
              min: 10,
            },
          ]}
        >
          <InputNumber placeholder="10" />
        </Form.Item>
        <Form.Item
          label="Price per request"
          name="per_price"
          rules={[
            {
              type: 'number',
              required: true,
              min: 0.00001,
            },
          ]}
        >
          <InputNumber placeholder="0.00001 ORAI" />
        </Form.Item>

        <Form.Item
          label="Validity period"
          name="validity_period"
          rules={[
            {
              type: 'number',
              required: true,
              min: 7,
            },
          ]}
        >
          <InputNumber placeholder="7 days" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPackageModal;
