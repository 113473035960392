import API from "services/api/API";

export const runRequest = (data: any): Promise<any> => {
  try {
    return API.request({
        url: API.EndPoint().UseRequest,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const runTestEndpoint = (data: any): Promise<any> => {
  try {
    return API.request({
        url: API.EndPoint().TestEndpoint,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const runDemo = (data: any): Promise<any> => {
  try {
    return API.request({
        url: API.EndPoint().UseDemo,
        method: 'POST',
        isAuthen: true,
        data
      })
      .then(res => res.data);
  } catch(err) {
    console.log(err);
    return Promise.reject(err);
  }
}