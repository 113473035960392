import { Button, Space, Table } from 'antd';
import { Endpoint, ServiceEntity } from 'entities/Entities';
import React from 'react';
import { ViewMode } from './Endpoints';

interface ListEndpointsProps {
  service: ServiceEntity;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  onEdit: (e: Endpoint) => void;
}

const ListEndpoints: React.FC<ListEndpointsProps> = React.memo(({ service, onEdit }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text: string, record: any, index: number) => <span>{index + 1}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '50%',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      width: '10%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            style={{ borderRadius: '4px', border: '1px solid #f44acf', color: '#f44acf' }}
            onClick={() => onEdit(record)}
          >
            Edit
          </Button>
          {/* <Button
            style={{borderRadius: '4px', border: '1px solid #f44acf', color: '#f44acf'}}
            onClick={() => {}}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  return <Table columns={columns} dataSource={service.endpoints} pagination={false} className="tbl_list-endpoints" />;
});

export default ListEndpoints;
