import { Button, message, Space, Spin, Table } from 'antd';
import { ClaimEntity, ClaimInfoEntity } from 'entities/Entities';
import { useAuthorization } from 'providers/AuthProvider';
import React, { useEffect, useState } from 'react';
import * as InteractContract from 'utils/interact-contract';

interface ClaimModalProps {
  handleCancel: () => void;
}

const ClaimModal: React.FC<ClaimModalProps> = ({ handleCancel }) => {
  const authState = useAuthorization().state;
  const [claimInfo, setClaimInfo] = useState<ClaimInfoEntity[]>([]);

  const handleClaim = async (recode: ClaimInfoEntity) => {
    try {
      await InteractContract.claim({ packageId: recode.package_id, customer: recode.customer });
      setClaimInfo(claimInfo.filter((c: ClaimInfoEntity) => c.package_id !== recode.package_id));
      message.success('Claim successfully!');
    } catch (err) {
      message.error('Claim failed!');
    }
  };

  const columns = [
    {
      title: 'Packages',
      dataIndex: 'package_id',
      key: 'package_id',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: 'Success requests',
      dataIndex: 'success',
      key: 'success',
      render: (text: any, record: ClaimInfoEntity) => (
        <span>{`${record.success_requests}/${record.number_requests}`}</span>
      ),
    },
    {
      title: 'Claimable',
      dataIndex: 'claimable_amount',
      key: 'claimable_amount',
      render: (text: any, record: ClaimInfoEntity) => <span>{+record.claimable_amount / 1000_000} ORAI</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: ClaimInfoEntity) => (
        <Space size="middle">
          <Button
            style={{ borderRadius: '4px', border: '1px solid #f44acf', color: '#f44acf' }}
            onClick={() => handleClaim(record)}
          >
            Claim
          </Button>
        </Space>
      ),
    },
  ];

  const fetchClaimInfos = async () => {
    const data = (await InteractContract.queryClaimInfoByUser(authState.user?.publicAddress || '')) || [];
    const claimInfo = data
      .map((d: ClaimEntity) => ({ ...d.claim_info }))
      .filter((ci: ClaimInfoEntity) => ci.claimable === true);
    setClaimInfo(claimInfo);
  };

  useEffect(() => {
    fetchClaimInfos();
  }, []);

  return (
    <>
      <Table columns={columns} dataSource={claimInfo} />
    </>
  );
};

export default ClaimModal;
