import React from 'react';
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import '../index.scss';
import BaseUrl from './BaseUrl';
import Endpoints from './endpoint/Endpoints';
import { ServiceEntity } from 'entities/Entities';

const { TabPane } = Tabs;

interface ConfigurationProps {
  service: ServiceEntity;
}

const Configuration: React.FC<ConfigurationProps> = React.memo(({ service }) => {
  return (
    <div className="ms-container">
      <Tabs defaultActiveKey="1" onChange={() => {}} className="ms-tabs">
        <TabPane tab="Base URL" key="1">
          <BaseUrl service={service} />
        </TabPane>
        <TabPane tab="Endpoints" key="2">
          <Endpoints service={service} />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default Configuration;
