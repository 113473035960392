import { UserEntity } from 'entities/Entities';
import React, { useContext, useEffect, useReducer } from 'react';
import { useMutation, useQuery } from 'react-query';
import LocalStorage, { LocalStorageKey } from 'services/LocalStorage';
import { getUser } from './hooks';

interface IAuthState {
  user: UserEntity | null;
  loading?: boolean;
  error?: any;
}

export enum AuthActionType {
  Login = 'Login',
  Logout = 'Logout',
  Reset = 'Reset',
}

const initialState: IAuthState = {
  user: null,
  loading: false,
  error: null,
};

export const AuthContext = React.createContext<{
  state: IAuthState;
  dispatch: (action: any) => void;
}>({
  state: initialState,
  dispatch: () => {},
});

export const isLoggedIn = () => {
  return !!LocalStorage.getItem(LocalStorageKey.token);
};

const reducer = (state: IAuthState, action: any): IAuthState => {
  switch (action.type) {
    case AuthActionType.Login:
      return {
        ...state,
        user: action.payload.user,
      };
    case AuthActionType.Logout:
    case AuthActionType.Reset:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  const getUserMutation = useMutation(getUser);
  const getUserAsync = async () => {
    try {
      const user = await getUserMutation.mutateAsync();
      dispatch({ type: AuthActionType.Login, payload: { user } });
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    const token = LocalStorage.getItem(LocalStorageKey.token);
    if (token) {
      getUserAsync();
    } else {
      dispatch({ type: AuthActionType.Reset });
    }
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthorization = () => useContext(AuthContext) ?? {};

export default AuthProvider;
