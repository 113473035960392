import { ClaimEntity } from "entities/Entities";

const AI_MARKETPLACE_CONTRACT = process.env.REACT_APP_AI_MARKETPLACE_CONTRACT|| '';

const handleExecute = async (
  contract: string,
  msg: string,
  { gas, fees, funds, memo, mode = 'BROADCAST_MODE_BLOCK' }: ExecuteOptions,
) => {
  try {
    const result = await window.Wasm.execute(contract, msg, { gas, fees, funds, memo, mode });

    return result;
  } catch (error) {
    console.error('error execute contract ', error);
    throw error;
  }
};

export const buyRequest = async ({ owner, package_id, number_requests, per_price, funds }: any) => {
  const msg = JSON.stringify({
    buy: {
      owner: owner.toString(),
      package_id: package_id.toString(),
      number_requests: number_requests.toString(),
      per_price: per_price.toString(),
    },
  });
  return handleExecute(AI_MARKETPLACE_CONTRACT, msg, { gas: 5000000, funds: funds.toString() });
}

export const claim = async ({ packageId, customer }: any) => {
  const msg = JSON.stringify({
    claim: {
      package_id: packageId.toString(),
      customer: customer.toString(), //address
    },
  });
  return handleExecute(AI_MARKETPLACE_CONTRACT, msg, { gas: 5000000 });
};

//=========================

export const queryClaimInfoByUser = async (user: string) => {
  try {
    const result = await window.Wasm.query(
      AI_MARKETPLACE_CONTRACT,
      JSON.stringify({
        get_claim_info_by_user: {
          user,
        },
      }),
    );
    if (result.code) {
      return false;
    }
    return result?.data as ClaimEntity[];
  } catch (error) {
    console.error('queryClaimInfoByUser error ', error);
    return false;
  }
};
