import { isLoggedIn } from "providers/AuthProvider";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({ children }: any) => {
  const location = useLocation();

  return isLoggedIn()
    ? children
    : <Navigate
        to="/"
        replace
        state={{ path: location.pathname }}
      />;
}

export default RequireAuth;