import _ from 'lodash';

export function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

export const createFileData = (files: any) => {
    files = [...files]; // they can be array-like object
    const fd = new FormData();
    fd.append('image', files[0]);
    return fd;
};

export const isValidJson = (str: string): boolean => {
  return !_.isError(_.attempt(JSON.parse.bind(null, str)));
}
export const validatePath = (path: string) => {
  let pattern = /^[\/\\](?:[.\/\\ ](?![.\/\\\n])|[^<>:"|?*.\/\\ \n])+$/;
  return pattern.test(path);
}

export const validateUrl = (url: string) => {
  let pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return pattern.test(url);
};
