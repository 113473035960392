import React from 'react';
import { Table, Space, Spin } from 'antd';
import './index.scss';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DEFAULT_PAGE_SIZE } from 'constants/ịndex';
import { PaginationEntity } from 'entities/Entities';
import { fetchBoughtServices } from './api';

const columns = [
  {
    title: 'Service name',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: any) => <span>{record.package?.service?.name}</span>,
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    render: (text: string, record: any) => <span>{record.package?.service?.user?.username}</span>,
  },
  {
    title: 'Number Requests',
    dataIndex: 'number_requests',
    key: 'number_requests',
  },
  {
    title: 'Per Price',
    dataIndex: 'per_price',
    key: 'per_price',
    render: (text: string, record: any) => <span>{record.package?.per_price} ORAI</span>,
  },
  {
    title: 'Validity Period',
    key: 'validity_period',
    dataIndex: 'validity_period',
    render: (text: string, record: any) => <span>{record.package?.validity_period} days</span>,
  },
  {
    title: 'Buy at',
    key: 'buy_at',
    dataIndex: 'buy_at',
    render: (text: string, record: any) => <span>{record.package?.created_at}</span>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record: any) => (
      <Space size="middle">
        <Link to={`/services/${record.service_id}`}>Use request</Link>
      </Space>
    ),
  },
];

// const data = [
//   {
//     key: '1',
//     id: 1,
//     number_requests: '12',
//     per_price: 0.001,
//     validity_period: '7 days',
//     created_at: '13/13/2013',
//   },
//   {
//     key: '2',
//     id: 2,
//     number_requests: '12',
//     per_price: 0.001,
//     validity_period: '7 days',
//     created_at: '13/13/2013',
//   },
//   {
//     key: '3',
//     id: 3,
//     number_requests: '12',
//     per_price: 0.001,
//     validity_period: '7 days',
//     created_at: '13/13/2013',
//   },
// ];

interface BoughtServicesProps {}

export default React.memo(({}: BoughtServicesProps) => {
  const [pagination, setPagination] = React.useState<PaginationEntity>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });

  const { isLoading, data: bought_services } = useQuery<any>(
    ['boughtServices', pagination],
    () => fetchBoughtServices(pagination),
    {
      keepPreviousData: true,
    },
  );

  const onChange = (currentPagination: any) => {
    setPagination({ ...pagination, page: currentPagination.current });
  };

  return (
    <div className="bought-container">
      {isLoading ? (
        <Spin />
      ) : (
        <Table
          className="bought-table"
          onChange={onChange}
          pagination={{ defaultPageSize: DEFAULT_PAGE_SIZE }}
          columns={columns}
          dataSource={bought_services.data}
        />
      )}
    </div>
  );
});
