/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Spin } from 'antd';
import RequireAuth from './RequireAuth';
import { Header } from 'components';
import ServiceManagement, { ViewModeSM } from 'pages/ServiceManagement/index';

// const Home = React.lazy(() => import('pages/Home'));
const Market = React.lazy(() => import('pages/Market'));
const NotFound = React.lazy(() => import('pages/NotFound'));
const ServiceDetail = React.lazy(() => import('pages/ServiceDetail'));

export default () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spin className="spin" />}>
        <Header />
        <Routes>
          <Route path="/" element={<Market />} />
          <Route path="/market" element={<Market />} />
          <Route
            path="/service-management"
            element={
              <RequireAuth>
                <ServiceManagement />
              </RequireAuth>
            }
          />
          <Route
            path="/service-management/mine/:id"
            element={
              <RequireAuth>
                <ServiceManagement viewMode={ViewModeSM.MyServices} />
              </RequireAuth>
            }
          />
          <Route
            path="/service-management/bought"
            element={
              <RequireAuth>
                <ServiceManagement viewMode={ViewModeSM.BoughtServices} />
              </RequireAuth>
            }
          />
          <Route
            path="/service-management/bought/:id"
            element={
              <RequireAuth>
                <ServiceManagement viewMode={ViewModeSM.BoughtServices} />
              </RequireAuth>
            }
          />
          <Route
            path="/service-management/claim"
            element={
              <RequireAuth>
                <ServiceManagement viewMode={ViewModeSM.Claim} />
              </RequireAuth>
            }
          />
          <Route path="/services/:id" element={<ServiceDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
