import { Button, Checkbox, Col, Row, Spin, Typography, message, Tooltip } from 'antd';
import { PackageEntity, ServiceEntity, ServiceStatus } from 'entities/Entities';
import React, { useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';
import {
  PlusCircleOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import AddPackageModal from './AddPackageModal';
import SellModal from './SellModal';
import WithdrawModal from './WithdrawModal';
import '../index.scss';
import { useMutation, useQueryClient } from 'react-query';
import { verifyService } from 'pages/ServiceManagement/hooks';
const { Text } = Typography;

interface PackageManagementProps {
  service: ServiceEntity;
}

const PackageManagement = React.memo(({ service }: PackageManagementProps) => {
  const queryClient = useQueryClient();

  const [openAddPackage, setOpenAddPackage] = useState(false);
  const [openSell, setOpenSell] = useState(false);
  const [status, setStatus] = useState(service?.status);
  const [loading, setLoading] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [checkedPackages, setCheckedPackages] = useState<number[]>([]);

  useEffect(() => {
    const onSalePkgs = service.packages?.filter((pkg) => pkg.on_sale).map((pkg: PackageEntity) => +pkg.id);
    setStatus(service?.status);
    onSalePkgs && setCheckedPackages([...onSalePkgs]);
  }, [service]);

  const onChangeSelectPackage = (e: any, pkg: PackageEntity) => {
    if (e.target.checked) {
      setCheckedPackages([...checkedPackages, +pkg.id]);
    } else {
      setCheckedPackages([..._.remove(checkedPackages, (pkgId) => pkgId !== e.target.value)]);
    }
  };

  const verifyServiceMutation = useMutation(verifyService, {
    onSuccess: (data: ServiceEntity) => {
      queryClient.refetchQueries('verifyService', { active: true, exact: true });
      setLoading(false);
      message.success('Service verification is on the way');
      setStatus(ServiceStatus.Pending);
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err.message);
    },
  });

  const onVerify = async () => {
    try {
      setLoading(true);
      await verifyServiceMutation.mutateAsync({ service_id: service.id });
    } catch (err: any) {
      console.log(err);
    }
  };

  const onChangeSelectAllPkgs = (e: any) => {
    if (e.target.checked) {
      const pkgIds = service.packages.map((pkg: PackageEntity) => +pkg.id);
      setCheckedPackages(pkgIds);
    } else {
      setCheckedPackages([]);
    }
  };

  if (isEmpty(service)) return <Spin className="spin" />;
  return (
    <div className="ms-container package-management">
      <div className="form-package">
        <div className="label-box row">
          <div className="btn-box row">
            <Button className="primary-button" htmlType="submit" onClick={() => setOpenAddPackage(true)}>
              <Text className="btn-txt">Add Package</Text>
              <PlusCircleOutlined />
            </Button>
          </div>
        </div>

        <div style={{ padding: '20px 0', justifyContent: 'space-between', display: 'flex' }}>
          <Text className="sub-label">
            <b>Total: {service.packages.length} packages</b> <Text className="sub-label light">(Unit: ORAI)</Text>
          </Text>
          {/* <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <Button className="sort">
                <Coolicon style={{ marginRight: 6 }} />
                Sort
              </Button>
            </Dropdown> */}
          <div className="btn-group">
            <span className="status">
              {status === ServiceStatus.Unverified ? (
                <QuestionCircleOutlined className="ic-status" />
              ) : status === ServiceStatus.Pending ? (
                <ClockCircleOutlined className="ic-status pending" />
              ) : (
                <CheckCircleOutlined className="ic-status verified" />
              )}

              {status === ServiceStatus.Unverified ? (
                <Tooltip
                  placement="rightBottom"
                  title={'Your service needs to be verified before being able to be sold.'}
                >
                  <Text className="status-txt">Unverified</Text>
                </Tooltip>
              ) : status === ServiceStatus.Pending ? (
                <Text className="status-txt pending">Pending</Text>
              ) : (
                <Text className="status-txt verified">Verified</Text>
              )}
            </span>

            {status === ServiceStatus.Unverified ? (
              <Button
                disabled={loading}
                loading={loading}
                className="secondary-button ml-btn"
                onClick={() => onVerify()}
              >
                <Text className="free-txt dark">Verify Now</Text>
              </Button>
            ) : null}

            {status !== ServiceStatus.Unverified ? (
              <>
                <Button
                  disabled={status === ServiceStatus.Pending}
                  className={`secondary-button ml-btn ${status === ServiceStatus.Pending ? 'disabled' : null}`}
                  onClick={() => checkedPackages.length && setOpenSell(true)}
                >
                  <Text className={`free-txt ${status === ServiceStatus.Pending ? 'disabled' : null}`}>
                    Sell package
                  </Text>
                </Button>

                <Button
                  disabled={status === ServiceStatus.Pending}
                  className={`secondary-button ml-btn  ${status === ServiceStatus.Pending ? 'disabled' : null}`}
                  onClick={() => service.on_sale && setOpenWithdraw(true)}
                >
                  <Text className={`free-txt dark ${status === ServiceStatus.Pending ? 'disabled' : null}`}>
                    Withdraw
                  </Text>
                </Button>
              </>
            ) : null}
          </div>
        </div>

        <div className="package-metadata-header">
          <div className="border-dashed">
            <Row gutter={16}>
              <Col span={4}>
                <div className="content-wrapper">
                  <b className="title">ID</b>
                </div>
              </Col>
              <Col span={6}>
                <div className="content-wrapper">
                  <b className="title">Validity period (days)</b>
                </div>
              </Col>
              <Col span={5}>
                <div className="content-wrapper">
                  <b className="title">Requests</b>
                </div>
              </Col>
              <Col span={5}>
                <div className="content-wrapper">
                  <b className="title">Price/request</b>
                </div>
              </Col>
              <Col span={3}>
                <div className="content-wrapper">
                  <b className="title">Total (ORAI)</b>
                </div>
              </Col>
              <Col span={1}>
                <div className="content-wrapper">
                  <p className="title">
                    <Checkbox
                      checked={checkedPackages.length === service.packages.length}
                      onChange={(e) => {
                        onChangeSelectAllPkgs(e);
                      }}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="package-metadata">
          <div className="border-dashed">
            {service.packages.map((pkg: PackageEntity) => (
              <Row gutter={16} key={pkg.id}>
                <Col span={4}>
                  <div className="content-wrapper">
                    <p className="content">{pkg.id}</p>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="content-wrapper">
                    <p className="content">{pkg.validity_period}</p>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="content-wrapper">
                    <p className="content">{pkg.number_requests}</p>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="content-wrapper">
                    <p className="content">{pkg.per_price}</p>
                  </div>
                </Col>
                <Col span={3}>
                  <div className="content-wrapper">
                    <p className="content">{+(+pkg.number_requests * +pkg.per_price).toFixed(6)}</p>
                  </div>
                </Col>
                <Col span={1}>
                  <div className="content-wrapper">
                    <p className="title">
                      <Checkbox
                        value={+pkg.id}
                        checked={checkedPackages.includes(+pkg.id)}
                        onChange={(e) => {
                          onChangeSelectPackage(e, pkg);
                        }}
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
      {openAddPackage && <AddPackageModal service={service} handleCancel={() => setOpenAddPackage(false)} />}
      {openSell && <SellModal packageIds={checkedPackages} service={service} handleCancel={() => setOpenSell(false)} />}
      {openWithdraw && <WithdrawModal service={service} handleCancel={() => setOpenWithdraw(false)} />}
    </div>
  );
});

export default PackageManagement;
