import { Button } from 'antd';
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import './index.scss';
import { ReactComponent as Dashboard1 } from 'assets/icons/dashboard-1.svg';
import { ReactComponent as Dashboard2 } from 'assets/icons/dashboard-2.svg';
import { ReactComponent as Dashboard3 } from 'assets/icons/dashboard-3.svg';
import { ReactComponent as Dashboard4 } from 'assets/icons/dashboard-4.svg';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const TEMP_LINK =
  'https://images.unsplash.com/photo-1642670310920-6f4e3a3adee3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=40';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface DashboardProps {}

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  datasets: [
    {
      label: 'First dataset',
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
    },
    {
      label: 'Second dataset',
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: '#742774',
    },
  ],
};

export default React.memo(({}: DashboardProps) => {
  return (
    <div className="ai-dashboard">
      <h3 className="title">Dashboard</h3>

      <Row gutter={30}>
        <Col span={6}>
          <div className="header-card">
            <h4 className="card-title">New services</h4>
            <span className="card-number">12</span>
            <div className="additional-info">
              <span className="card-changed-number">+5%</span>
              <span className="card-changed-text"> than last month</span>
            </div>
            <Dashboard1 />
          </div>
        </Col>
        <Col span={6}>
          <div className="header-card">
            <h4 className="card-title">New services</h4>
            <span className="card-number">12</span>
            <div className="additional-info">
              <span className="card-changed-number">+5%</span>
              <span className="card-changed-text"> than last month</span>
            </div>
            <Dashboard2 />
          </div>
        </Col>
        <Col span={6}>
          <div className="header-card">
            <h4 className="card-title">New services</h4>
            <span className="card-number">12</span>
            <div className="additional-info">
              <span className="card-changed-number">+5%</span>
              <span className="card-changed-text"> than last month</span>
            </div>
            <Dashboard3 />
          </div>
        </Col>
        <Col span={6}>
          <div className="header-card">
            <h4 className="card-title">New services</h4>
            <span className="card-number">12</span>
            <div className="additional-info">
              <span className="card-changed-number">+5%</span>
              <span className="card-changed-text"> than last month</span>
            </div>
            <Dashboard4 />
          </div>
        </Col>
      </Row>

      <div style={{ margin: 40 }}></div>

      <Row gutter={30}>
        <Col span={16}>
          <div className="main-chart">
            <h5>Income Overview</h5>
            <div className="chart-wrapper">
              <Line data={data} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="news">
            <h5>News</h5>
            <div className="news-wrapper">
              <div className="news-card">
                <img src={TEMP_LINK} alt="temp-image" />
                <div className="news-body">
                  <h6 className="news-title">No1 fighter</h6>
                  <p className="news-content">
                    Ông Nguyễn Quốc Anh bị VKS cáo buộc "nóng vội" chỉ đạo cấp dưới bỏ qua quy trình lắp đặt robot phẫu
                    thuật, gây thiệt hại hơn 10 tỷ đồng cho hơn 600 bệnh nhân.
                  </p>
                  <a href="">Read more</a>
                </div>
              </div>
              <div className="news-card">
                <img src={TEMP_LINK} alt="temp-image" />
                <div className="news-body">
                  <h6 className="news-title">No1 fighter</h6>
                  <p className="news-content">
                    Ông Nguyễn Quốc Anh bị VKS cáo buộc "nóng vội" chỉ đạo cấp dưới bỏ qua quy trình lắp đặt robot phẫu
                    thuật, gây thiệt hại hơn 10 tỷ đồng cho hơn 600 bệnh nhân.
                  </p>
                  <a href="">Read more</a>
                </div>
              </div>
              <div className="news-card">
                <img src={TEMP_LINK} alt="temp-image" />
                <div className="news-body">
                  <h6 className="news-title">No1 fighter</h6>
                  <p className="news-content">
                    Ông Nguyễn Quốc Anh bị VKS cáo buộc "nóng vội" chỉ đạo cấp dưới bỏ qua quy trình lắp đặt robot phẫu
                    thuật, gây thiệt hại hơn 10 tỷ đồng cho hơn 600 bệnh nhân.
                  </p>
                  <a href="">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* <Button className="btn" onClick={() => setOpenAddService(true)}>
        Add services
      </Button> */}
    </div>
  );
});
