import { BalanceEntity, UserEntity } from "entities/Entities";
import API from "services/api/API";

export const getUser = (): Promise<UserEntity> => {
  try {
    return API.request({
        url: API.EndPoint().GetUser,
        method: 'GET',
        isAuthen: true,
      })
      .then(res => res.data as UserEntity);
  } catch(err) {
    console.log(err);
    return Promise.reject();
  }
}

export const getBalance = async (user: UserEntity | null): Promise<BalanceEntity | null> => {
  if (!user) return null;
  try {
    const { balance } = await window.Wasm.cosmos.get(`/cosmos/bank/v1beta1/balances/${user.publicAddress}/by_denom?denom=orai`);
    return balance;
  } catch(err) {
    console.log(err);
    return null;
  }
}