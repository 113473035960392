import { isLoggedIn } from 'providers/AuthProvider';
import React, { useEffect, useState } from 'react';
import LogoutButton from './LogoutButton';
import RequireAuthButton from './RequireAuthButton';
import { useAuthorization } from 'providers/AuthProvider';
import { Avatar, Dropdown, message } from 'antd';
import { UserOutlined, CopyOutlined } from '@ant-design/icons';
import { getBalance } from 'providers/hooks';

const ConnectWalletButton: React.FC<{}> = () => {
  const auth = useAuthorization();
  const [amount, setAmount] = useState(0);
  const [visible, setVisible] = useState(false);

  const onVisibleChange = async (visible: boolean) => {
    setVisible(visible);
    if (visible) {
      const bl = await getBalance(auth.state.user);
      const amount = bl ? +bl.amount / 1_000_000 : 0;
      setAmount(amount);
    }
  }

  const handleCopy = (txt: any) => {
    navigator.clipboard.writeText(txt);
    message.success('Copied!');
  };

  const menu = (
    <div className="hd-container">
      <div className="hd-title">
        <p className="hd-label">My account</p>
      </div>
      <div className="hd-dropdown">
        <div className="hd-menu-item">
          <span className="hd-wallet">
            <div className="hd-circle" />
            ORAICHAIN WALLET
          </span>
          <div className="hd-label">
            {auth?.state.user?.username}
            <p className="hd-content">
              {auth?.state.user?.publicAddress}
              <button
                className="hd-copy"
                onClick={() => {
                  handleCopy(auth?.state.user?.publicAddress);
                }}
              >
                <CopyOutlined />
              </button>
            </p>
          </div>
          <div className="hd-wallet-info">
            {/* <Avatar size="large" icon={<UserOutlined />} /> */}
            <div className="hd-info-right">
              <span className="hd-balance">Balance:</span>
              <span className="hd-orai">{amount} ORAI</span>
            </div>
          </div>
        </div>
        <div className="hd-logout">
          <LogoutButton />
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {!isLoggedIn() ? (
        <RequireAuthButton className="btn-connect" style={{}}>
          <p className="btn-connect">Connect Wallet</p>
        </RequireAuthButton>
      ) : (
        <Dropdown trigger={['click']} overlay={menu} placement="bottomCenter" arrow onVisibleChange={onVisibleChange} visible={visible}>
          <Avatar icon={<UserOutlined />} />
        </Dropdown>
      )}
    </React.Fragment>
  );
};

export default ConnectWalletButton;
