import React, { useState } from 'react';
import ClaimModal from './ClaimModal';
import './../index.scss';
interface ClaimProps {}

export default React.memo(({}: ClaimProps) => {
  const [openClaim, setOpenClaim] = useState(false);

  return (
    <div className="claim-body">
      <h3>Claim</h3>
      <div className="claim-body-wrapper">
        <ClaimModal handleCancel={() => setOpenClaim(false)} />
      </div>
    </div>
  );
});
