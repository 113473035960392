import { Endpoint, EndpointParam } from "entities/Entities";
import { isEmpty } from "lodash";

const validation = (endpoint: Endpoint) => {
  const { name, description, method, path, params } = endpoint;
  let errors: string[] = [];

  if (isEmpty(name)) errors.push("Missing endpoint name.");
  if (isEmpty(description)) errors.push("Missing endpoint description.");
  if (isEmpty(method)) errors.push("Missing endpoint method.");
  if (isEmpty(path)) errors.push("Missing endpoint path.");


  if (!isEmpty(params)) {
    const invalidHeader = params.headers?.find((h: EndpointParam) => h.isRequired && isEmpty(h.name));
    if (invalidHeader) errors.push("Missing headers param.");

    const invalidQuery = params.query?.find((q: EndpointParam) => q.isRequired && isEmpty(q.name));
    if (invalidQuery) errors.push("Missing query param.");

    if (params.body) {
      if (params.body.formData) {
        const invalidBody = params.body.data.find((d: EndpointParam) => d.isRequired && isEmpty(d.name));
        if (invalidBody) errors.push("Missing body param.");
      }
    }
  }

  return {
    errors
  }
}

export default validation;