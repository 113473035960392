import React from 'react';
import './App.scss';
import Routes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'antd/dist/antd.css';
import AuthProvider from 'providers/AuthProvider';
import ViewProvider from 'providers/ViewProvider';
import './services/wasm';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ViewProvider>
          <Routes />
        </ViewProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
