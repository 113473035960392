import axios, { AxiosRequestConfig, Method, AxiosPromise, CancelToken } from 'axios';
import LocalStorage, { LocalStorageKey } from 'services/LocalStorage';

export type Request = {
    method: Method;
    url: string;
    data?: any;
    params?: any;
    token?: string;
    baseURL?: string;
    contentType?: string;
    cancelToken?: CancelToken;
    isAuthen?: boolean,
};

export type Response = {
    data: any;
    error: any;
};

const API = {
  EndPoint: (params?: any) => ({
    OnSale: '/ai-market/onsale-services',
    MyServices: '/ai-market/own-services',
    BoughtServices: '/ai-market/bought-services',
    BuyRequest: '/ai-market/buy-request',
    ServiceDetail: `/ai-market/service/${params?.id}`,
    AddService: `/ai-market/service`,
    UpdateService: `/ai-market/service/${params?.id}`,
    VerifyService: `/ai-market/verify-service`,
    Uploader: `/uploader/upload`,
    AddPackage: `/ai-market/package`,
    SellService: `/ai-market/sell`,
    WithdrawService: `/ai-market/withdraw`,
    RateService: `/ai-market/rating`,
    UseRequest: '/ai-market/use-request',
    GetUser: '/ai-market/user',
    UseDemo: '/ai-market/use-demo',
    GetCategory: '/ai-market/category',
    Rate: '/ai-market/rating',
    ChangeServiceConfig: '/ai-market/service-config',
    ChangeEndpoint: '/ai-market/service-endpoint',
    TestEndpoint: '/ai-market/test-endpoint',
  }),

  request: <T>(reqParam: Request): AxiosPromise<T> => {
    const config: AxiosRequestConfig = {...reqParam};
    config.baseURL = process.env.REACT_APP_API_NODE;

    if (reqParam.isAuthen) {
      let accessToken = reqParam.token;
      if (!accessToken) {
        const token = LocalStorage.getItem(LocalStorageKey.token);
        if (!token) throw new Error("Unauthorized!");
        accessToken = token;
      }

      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': reqParam.contentType || "application/json",
      };
    }
    
    const req = axios.request(config);
    return req;
  }
};

export default API;
