import '../index.scss';
import { Button, Col, Form, Input, Row, Typography, Image, Select, message } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as TrashIC } from 'assets/icons/trash_empty.svg';
import Dragger from 'antd/lib/upload/Dragger';
import { ReactComponent as UploadIC } from 'assets/icons/upload.svg';
import { ServiceEntity, UploadEntity } from 'entities/Entities';
import { uploader, useFetchCategory } from 'services/commons';
import { useQuery } from 'react-query';
import { createFileData, getBase64 } from 'utils/function';
import { useMutation, useQueryClient } from 'react-query';
import { updateService } from '../../hooks';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface OverviewProps {
  service: ServiceEntity;
}

const Overview: FunctionComponent<OverviewProps> = ({ service }) => {
  const queryClient = useQueryClient();
  const { data: categories } = useQuery<any>(['categories'], useFetchCategory);
  const [form] = Form.useForm();
  const [preview, setPreview] = useState<any>({});
  const [thumb, setThumb] = useState<any>();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState<any>();

  React.useEffect(() => {
    form.setFieldsValue({
      name: service.name || '',
      category: service.category.id || '',
      description: service.description || '',
    });
    setPreview(service.thumbnail);
  }, [form, service]);

  const updateServiceMutation = useMutation(updateService, {
    onSuccess: (data: ServiceEntity) => {
      queryClient.refetchQueries('myServices', { active: true, exact: true });
      setLoading(false);
      setDisabled(true);
      message.success('Service added successfully');
    },
    onError: (err: any) => {
      message.error(err.message);
      setLoading(false);
    },
  });

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: true,
    onRemove: () => {
      setThumb(null);
      setPreview(null);
    },
    beforeUpload: (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest: (file: any) => {
      onUploadThumb();
    },
    onChange: async (info: any) => {
      setThumb(info);
      const previewImg = await getBase64(info.fileList[0].originFileObj ?? info.file.originFileObj);
      setPreview(previewImg);
    },
  };

  const uploaderMutation = useMutation(uploader, {
    onSuccess: async (data: UploadEntity) => {
      return data;
    },
    onError: (err: any) => {
      message.error(err.message);
    },
  });

  const handleSave = async (values: any) => {
    try {
      setLoading(true);
      const { name, description, category } = values;
      await updateServiceMutation.mutateAsync({
        name,
        description,
        category_id: category,
        thumbnail: preview ?? service.thumbnail,
        id: service.id,
      });
    } catch (err: any) {
      console.log(err);
    }
  };
  const onUploadThumb = async () => {
    if (thumb && thumb !== null) {
      const FileList = thumb['fileList']?.map((file: any) => {
        return file.originFileObj;
      });

      const formDataFiles = createFileData(FileList);
      const response = await uploaderMutation.mutateAsync(formDataFiles);
      setPreview(response.link);
      return response.link;
    }
    return null;
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    setDisabled(false);
  };

  return (
    <div className="ms-container overview">
      <div className="upload-thumb">
        <div className="group-title">
          <Text className="as-title">UPLOAD THUMBNAIL</Text>
          <Button className="btn" htmlType="button" onClick={handleEdit}>
            Edit
          </Button>
        </div>
        {preview ? (
          <div className="as-image-container">
            <Image
              className="as-image"
              src={preview ?? 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
            />
            {disabled ? null : (
              <button
                className="as-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setThumb(null);
                  setPreview(null);
                }}
              >
                <TrashIC width={30} height={30} />
              </button>
            )}
          </div>
        ) : (
          <div className="drag-zone">
            <Dragger style={{ marginTop: 15, borderColor: '#DD4AE0', borderRadius: 8, width: 200 }} {...props}>
              <p className="ant-upload-drag-icon">
                <UploadIC />
              </p>
              <p className="ant-upload-hint">
                Drop your image here, or <Text style={{ color: '#DD4AE0' }}>Browse</Text>
              </p>
            </Dragger>
          </div>
        )}
      </div>
      <Form
        name="basic"
        form={form}
        onFinish={handleSave}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
      >
        <div className="as-content">
          <Row className="as-row">
            <Col span={12}>
              <p className="as-label">
                Name <span className="as-require">*</span>
              </p>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input name service!',
                  },
                ]}
              >
                <Input className="as-input small" placeholder="Eg. Super Art Generative Model" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="as-row">
            <Col span={12}>
              <p className="as-label">
                Category <span className="as-require">*</span>
              </p>
              <Form.Item
                name="category"
                rules={[
                  {
                    required: true,
                    message: 'Please select category!',
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  placeholder="Category"
                  bordered={false}
                  className="select-package"
                  onChange={() => {}}
                >
                  {categories?.map((cate: any) => (
                    <Option key={cate.id} value={cate.id}>
                      {cate.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="as-label">
                Description <span className="as-require">*</span>
              </p>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input description service!',
                  },
                ]}
              >
                <TextArea
                  className={'as-input'}
                  style={{ minHeight: 150 }}
                  placeholder={`Eg. Super Art Generative Model`}
                  allowClear
                  onChange={() => {}}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="btn-group">
            <Form.Item>
              <Button
                className={`btn-grad ${disabled ? 'disabled' : ''}`}
                htmlType="submit"
                disabled={disabled}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Overview;
