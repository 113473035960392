import { UploadEntity } from "entities/Entities";
import API from "services/api/API";

export const uploader = (data: any): Promise<UploadEntity> => {
    try {
      return API.request({
          url: API.EndPoint().Uploader,
          method: 'POST',
          isAuthen: true,
          data,
          contentType:  'multipart/form-data',
        
        })
        .then(res => res.data as UploadEntity);
    } catch(err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
  
  export const useFetchCategory = () => {
    try {
      return API.request({
          url: API.EndPoint().GetCategory,
          method: 'GET',
          isAuthen: false
        })
        .then(res => {
          return res.data
        });
    } catch(err) {
      console.log(err);
      return [];
    }
  }