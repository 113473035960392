import API from 'services/api/API';
export const fetchBoughtServices = (params: any) => {
  try {
    return API.request({
      url: API.EndPoint().BoughtServices,
      method: 'GET',
      isAuthen: true,
      params,
    }).then((res) => res.data);
  } catch (err) {
    console.log(err);
    return Promise.resolve([]);
  }
};
