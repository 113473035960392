import {  message, Modal, Row } from "antd";
import { ServiceEntity } from "entities/Entities";
import { sellService } from "pages/ServiceManagement/hooks";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

interface SellModalProps {
  handleCancel: () => void;
  service: ServiceEntity;
  packageIds: number[];
}

const SellModal: React.FC<SellModalProps> = ({ handleCancel, service, packageIds }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const sellMutation = useMutation(sellService, {
    onSuccess: (data: any) => {
      queryClient.refetchQueries(['service', { id: service.id, isAuthen: true }], { active: true, exact: true });
      message.success('Sell successfully!');
      setLoading(false);
      handleCancel();
    },
    onError: (error: any) => {
      message.error('Sell failed!');
      setLoading(false);
      handleCancel();
    },
  });

  const onFinish = async () => {
    if (!packageIds.length) return;
    setLoading(true);
    await sellMutation.mutate({
      sell_packages: JSON.stringify(packageIds),
      service_id: service.id,
    });
  };

  return (
    <Modal
      title="Add package"
      visible={true}
      onOk={onFinish}
      onCancel={handleCancel}
      okText="Sell"
      confirmLoading={loading}
    >
      <p>Are you sure you want to sell all the packages of this service?</p>
    </Modal>
  );
};

export default SellModal;
