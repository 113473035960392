import { Input, message } from 'antd';
import { Endpoint } from 'entities/Entities';
import React, { useEffect } from 'react';
import { validateUrl } from 'utils/function';

interface InfoProps {
  endpoint: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint>>;
}

enum RefIdx {
  Name,
  Description,
  InstructionLink,
}

const Info: React.FC<InfoProps> = React.memo(({ setEndpoint, endpoint }) => {
  const refs = React.useRef<any>([]);
  const onBlur = (idx: RefIdx) => {
    const val = refs.current[idx]?.input?.value || '';
    switch (idx) {
      case RefIdx.Name:
        setEndpoint({ ...endpoint, name: val });
        return;
      case RefIdx.Description:
        setEndpoint({ ...endpoint, description: val });
        return;
      case RefIdx.InstructionLink:
        if (!validateUrl(val)) {
          message.error('Invalid URL');
          return;
        }
        setEndpoint({ ...endpoint, instruction_link: val });
        return;
    }
  };

  return (
    <>
      <div className="input_item">
        <div className='as-label'>
          Name <span className="as-require">*</span>
        </div>
        <Input
          ref={(el: any) => {
            refs.current[RefIdx.Name] = el;
          }}
          onBlur={() => onBlur(RefIdx.Name)}
          defaultValue={endpoint?.name}
        />
      </div>
      <div className="input_item">
        <div className='as-label'>
          Description <span className="as-require">*</span>
        </div>
        <Input
          ref={(el: any) => {
            refs.current[RefIdx.Description] = el;
          }}
          onBlur={() => onBlur(RefIdx.Description)}
          defaultValue={endpoint?.description}
        />
      </div>
      <div className="input_item">
        <div className='as-label'>Instruction link</div>
          <Input
            ref={(el: any) => {
              refs.current[RefIdx.InstructionLink] = el;
            }}
            onBlur={() => onBlur(RefIdx.InstructionLink)}
            defaultValue={endpoint?.instruction_link}
          />
      </div>
    </>
  );
});

export default Info;
