import { Avatar, Menu } from 'antd';
import React, { useState } from 'react';
import {
  DashboardOutlined,
  DollarCircleOutlined,
  RobotOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import { ViewModeSM } from ".";
import { ServiceEntity } from "entities/Entities";
import { useAuthorization } from 'providers/AuthProvider';
import AddServiceModal from './dashboard/AddService';
const { SubMenu } = Menu;

interface MenuProps {
  viewMode: ViewModeSM;
  myServices: ServiceEntity[];
}

export default React.memo(({ viewMode, myServices = [] }: MenuProps) => {
  const [openAddService, setOpenAddService] = useState(false);
  const auth = useAuthorization();
  const params = useParams();
  let key = ViewModeSM.Dashboard.toString();
  let openKey = '';

  if (viewMode === ViewModeSM.Dashboard || viewMode === ViewModeSM.Claim) key = `${viewMode}`;
  if (viewMode === ViewModeSM.MyServices) {
    key = `ms_${params.id}`;
    openKey = `${ViewModeSM.MyServices}`;
  }

  if (viewMode === ViewModeSM.BoughtServices) {
    // key = `ms_${params.id}`;
    openKey = `${ViewModeSM.BoughtServices}`;
  }

  return (
    <>
      <Menu
        defaultSelectedKeys={[`${key || 0}`]}
        defaultOpenKeys={[`${openKey}`]}
        mode="inline"
        theme="dark"
        // inlineCollapsed={}
        className="menu_management"
      >
        <div className="header">
          <div className="avatar_wrapper">
            <Avatar size="large" icon={<UserOutlined />} className="avatar" />
          </div>
          <div className="username">{auth.state?.user?.username}</div>
        </div>
        <div className="create-service">
          <PlusCircleOutlined />
          <span className="txt" onClick={() => setOpenAddService(true)} style={{cursor: "pointer"}}>
            Create Service
          </span>
        </div>

        <Menu.Item key={ViewModeSM.Dashboard} icon={<DashboardOutlined />}>
          <Link to={`/service-management`}>Dashboard</Link>
        </Menu.Item>
        <Menu.Item key={ViewModeSM.Claim} icon={<DollarCircleOutlined />}>
          <Link to={`/service-management/claim`}>Claim</Link>
        </Menu.Item>
        <SubMenu key={ViewModeSM.MyServices} icon={<RobotOutlined />} title="My services">
          {myServices?.map((ms: ServiceEntity) => (
            <Menu.Item key={`ms_${ms.id}`}>
              <Link to={`/service-management/mine/${ms.id}`}>{ms.name}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item key={ViewModeSM.BoughtServices} icon={<DollarCircleOutlined />}>
          <Link to={`/service-management/bought`}>Bought services</Link>
        </Menu.Item>
        {/* <SubMenu key={ViewModeSM.BoughtServices} icon={<ShoppingOutlined />} title="Bought services">
          <Menu.Item key="4">
            <Link to={`/service-management/bought/1`}>Service 4</Link>
          </Menu.Item>
        </SubMenu> */}
      </Menu>
      {openAddService && <AddServiceModal handleCancel={() => setOpenAddService(false)} />}
    </>
  );
});
