import { Button, Form, Input, message, Spin } from "antd";
import { BodyParam, ContentType, Endpoint, EndpointMethod, EndpointParams, HeaderParam, QueryParam, ServiceEntity } from "entities/Entities";
import { isEmpty } from "lodash";
import React, { useState } from "react";  
import { useMutation, useQueryClient } from "react-query";
import { runTestEndpoint } from "./hooks";
import './index.scss';

interface TestEndpointFormProps {
  service: ServiceEntity,
  endpoint: Endpoint,
  setInput?: any,
  input?: InputType,
}

type InputType = {
  headers?: {},
  data_json?: "",
  data_text?: "",
  data_form?: {},
  query?: {} 
}

const Headers: React.FC<TestEndpointFormProps> = ({service, endpoint, setInput, input={}}) => {
  const params = endpoint.params as EndpointParams;
  const headers = params?.headers || [];
  const refs = React.useRef<any>([]);

  const onBlur = (idx: string) => {
    const val = refs.current[idx]?.input?.value || "";
    setInput &&
      setInput({
        ...input,
        headers: {
          ...input.headers,
          [`${idx}`]: val
        }
      });
  }

  if (isEmpty(headers)) return null;
  return (
    <div className="headers">
      <div className="title">Header</div>
      {headers.map((h: HeaderParam) => (
        <>
          <div>{h.name} {h.isRequired && <span className="as-require">*</span>}</div>
          <Input 
            ref={(el: any) => {refs.current[`${h.name}`] = el}}
            onBlur={() => onBlur(`${h.name}`)}
          />
        </>
      ))}
    </div>
  );
}

const Query: React.FC<TestEndpointFormProps> = ({service, endpoint, setInput, input={}}) => {
  const params = endpoint.params as EndpointParams;
  const query = params?.query || [];
  const refs = React.useRef<any>([]);

  const onBlur = (idx: string) => {
    const val = refs.current[idx]?.input?.value || "";
    setInput &&
      setInput({
        ...input,
        query: {
          ...input.query,
          [`${idx}`]: val
        }
      });
  }

  if (isEmpty(query)) return null;
  return (
    <div className="query">
      <div className="title">Query</div>
      {query.map((h: HeaderParam) => (
        <>
          <div>{h.name} {h.isRequired && <span className="as-require">*</span>}</div>
          <Input 
            ref={(el: any) => {refs.current[`${h.name}`] = el}}
            onBlur={() => onBlur(`${h.name}`)}
          />
        </>
      ))}
    </div>
  );
}

const Body: React.FC<TestEndpointFormProps> = ({service, endpoint, setInput, input={}}) => {
  const params = endpoint.params as EndpointParams;
  const body = params?.body || {} as BodyParam;
  const refs = React.useRef<any>([]);
  const [form] = Form.useForm();

  const updateDataJson = (idx: string) => {
    const val = refs.current[idx]?.resizableTextArea?.props?.value || "";
    setInput &&
      setInput({
        ...input,
        data_json: val
      });
  }

  const updateDataText = (idx: string) => {
    const val = refs.current[idx]?.resizableTextArea?.props?.value || "";
    setInput &&
      setInput({
        ...input,
        data_text: val
      });
  }

  const updateDataForm = (idx: string) => {
    const val = refs.current[idx]?.input?.value || "";
    const dataForm = input.data_form || {};
    setInput &&
      setInput({
        ...input,
        data_form: {
          ...dataForm,
          [`${idx}`]: val
        }
      });
  }

  if (isEmpty(body)) return null;
  return (
    <div className="body">
      <div className="title">Body</div>
      <div className="content-type"><span className="as-label">Content Type:</span> {body.contentType}</div>
      {
        (body.contentType.toUpperCase() == ContentType["application/json"].toUpperCase()) &&
        (
          <Form
            name="basic"
            form={form}
            onFinish={()=>{}}
            onFinishFailed={()=>{}}
            autoComplete="off"
            layout="vertical"
          >
            <p className="as-label">
              Data <span className="as-require">*</span>
            </p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Missing input',
                },
              ]}
            >
              <Input.TextArea
                className={'as-input'}
                style={{ minHeight: 150 }}
                placeholder={`${body.jsonData || ""}`}
                allowClear
                onChange={() => {}}
                onBlur={() => updateDataJson(`data_json`)}
                ref={(el: any) => {refs.current[`data_json`] = el}}
              />
            </Form.Item>
          </Form>
        )
      }
      {
        (body.contentType.toUpperCase() == ContentType["text/plain"].toUpperCase()) &&
        (
          <Form
            name="basic"
            form={form}
            onFinish={()=>{}}
            onFinishFailed={()=>{}}
            autoComplete="off"
            layout="vertical"
          >
            <p className="as-label">
              Data <span className="as-require">*</span>
            </p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Missing input',
                },
              ]}
            >
              <Input.TextArea
                className={'as-input'}
                style={{ minHeight: 150 }}
                placeholder={`${body.textData || ""}`}
                allowClear
                onChange={() => {}}
                onBlur={() => updateDataText(`data_text`)}
                ref={(el: any) => {refs.current[`data_text`] = el}}
              />
            </Form.Item>
          </Form>
        )
      }
      {
        (body.contentType.toUpperCase() == ContentType["multipart/form-data"].toUpperCase()) &&
          body.formData?.map((h: HeaderParam) => (
            <>
              <div>{h.name} {h.isRequired && <span className="as-require">*</span>}</div>
              <Input 
                ref={(el: any) => {refs.current[`${h.name}`] = el}}
                onBlur={() => updateDataForm(`${h.name}`)}
              />
            </>
          ))
      }
    </div>
  );
}

const Output: React.FC<TestEndpointFormProps & { output: any }> = ({service, endpoint, output}) => {  
  return (
    <div className="output">
      <div className="title">Output</div>
      {output}
    </div>
  );
}

const TestEndpointForm: React.FC<TestEndpointFormProps> = ({service, endpoint}) => {  
  const [input, setInput] = useState<InputType>({});
  const [output, setOutput] = useState<any>("");
  const [loading, setLoading] = useState(false);

  const runTestEndpointMutation = useMutation(runTestEndpoint, {
    onSuccess: (data: any) => {
      setOutput(JSON.stringify(data));
      message.success('Run successfully!');
      setLoading(false);
    },
    onError: (error: any) => {
      setOutput(JSON.stringify(error.response?.data?.message || error.message));
      message.error('Run failed!');
      setLoading(false);
    },
  });

  const run = async () => {
    if (!input || !endpoint) return;

    setLoading(true);
    await runTestEndpointMutation.mutate({
      input,
      endpoint_id: endpoint.id
    });
  };

  if (!service || !endpoint) return <Spin className="spin"/>
  return (
    <div className="test-endpoint-form">
      <Headers service={service} endpoint={endpoint} setInput={setInput} input={input}/>
      <Query service={service} endpoint={endpoint} setInput={setInput} input={input}/>
      <Body service={service} endpoint={endpoint} setInput={setInput} input={input}/>
      <Button className="btn" onClick={()=>run()} loading={loading}>Run test</Button>
      <Output service={service} endpoint={endpoint} output={output} />
    </div>
  );
}

export default TestEndpointForm;