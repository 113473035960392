import Cosmos from '@oraichain/cosmosjs';
import { BIP32Interface, fromPrivateKey } from 'bip32';
import { Buffer } from 'buffer';
import { sha256 } from 'js-sha256';
import LocalStorage, { LocalStorageKey } from './LocalStorage';

/** basically query and execute, with param : contract, lcd, and simulate is true or false
 */
const message = Cosmos.message;

/**
 * If there is chainId it will interacte with blockchain, otherwise using simulator
 */
class Wasm {
  public cosmos: Cosmos;
  constructor(baseUrl: string, chainId = 'Simulate') {
    this.cosmos = new Cosmos(baseUrl.replace(/\/$/, ''), chainId);
    this.cosmos.setBech32MainPrefix('orai');
  }

  /**
   * query with json string
   * */
  async query(address: string, input: string) {
    const param = Buffer.from(input).toString('base64');
    if (this.cosmos.chainId === 'Simulate') {
      return this.cosmos.get(`/wasm/contract/${address}/query/${param}`);
    }
    return this.cosmos.get(`/wasm/v1beta1/contract/${address}/smart/${param}`);
  }

  /**
   * execute smart contract require childKey when interacting with blockchain
   * @param {*} address   : the wallet address
   * @param {*} input     : the string from JSON.stringify
   * @param {*} pathOrChildKey   : can be account on simulate or hd path | childKey on blockchain
   * @param {*} options   : custom options {gas, fees, funds: for swap native token}
   * @returns Promise<any>
   */

  async execute(
    address: string,
    input: string,
    { gas, fees, funds, memo, mode = 'BROADCAST_MODE_SYNC' }: ExecuteOptions = {},
  ): Promise<any> {
    const param = Buffer.from(input);
    const pathOrChildKey = await this.getChildKey();
    if (this.cosmos.chainId === 'Simulate') {
      return this.cosmos.get(`/wasm/contract/${address}/handle/${param.toString('base64')}?account=${pathOrChildKey}`);
    }

    const childKey =
      typeof pathOrChildKey === 'string' ? await this.getChildKey(pathOrChildKey as string) : pathOrChildKey;
    if (!childKey) {
      return;
    }
    const sender = this.cosmos.getAddress(childKey);
    const txBody = this.getHandleMessage(address, param, sender, funds, memo);

    // @ts-ignore
    return this.cosmos.submit(childKey, txBody, mode, fees ? fees : 0, gas ? gas : 200000);
  }

  cacheChildkey(childkeyAttributes: any) {
    LocalStorage.saveItem(LocalStorageKey.childkey, childkeyAttributes, 1000 * 60 * 60 * 12);
  }

  getCachedChildkey() {
    const value = LocalStorage.getItem(LocalStorageKey.childkey);

    if (!value) {
      return null;
    }

    // migrate to old users
    if (value?.privateKey?.data) {
      this.removeChildKey();

      return null;
    }

    return fromPrivateKey(
      Buffer.from(value?.privateKey, 'base64'),
      Buffer.from(value?.chainCode, 'base64'),
      value?.network,
    );
  }

  removeChildKey() {
    LocalStorage.removeItem(LocalStorageKey.childkey);
  }

  async getChildKey(path?: string, noCache?: boolean): Promise<BIP32Interface | undefined> {
    if (window.Wallet) {
      const cachedChildkey = this.getCachedChildkey();
      if (cachedChildkey && !noCache) {
        return cachedChildkey;
      }

      let childKeyValue = await window.Wallet.getChildKey(path);

      // @ts-ignore
      const { privateKey, chainCode, network } = childKeyValue;
      const childKey = fromPrivateKey(Buffer.from(privateKey), Buffer.from(chainCode), network);

      this.cacheChildkey({
        privateKey: Buffer.from(privateKey).toString('base64'),
        chainCode: Buffer.from(chainCode).toString('base64'),
        network,
      });

      return childKey;
    }
  }

  requestGetChildKey = () => {
    const postMessageProxy = function (data: any) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(data);
    };
    postMessageProxy(JSON.stringify({ type: 'Login' }));
  };

  /**
   * get the public wallet address given a child key
   * @returns string
   */
  getAddress(childKey: any): string {
    return this.cosmos.getAddress(childKey);
  }

  /**
   * get an object containing marketplace and ow721 contract addresses
   * @returns ContractAddress
   */
  get contractAddresses(): ContractAddress {
    return {
      marketplace: process.env.REACT_APP_MARKET_PLACE_CONTRACT,
      ow721: process.env.REACT_APP_NFT_TOKEN_CONTRACT,
      lock: process.env.REACT_APP_LOCK_CONTRACT_ADDR,
      auction: process.env.REACT_APP_AUCTION_CONTRACT,
    };
  }

  get statusCode(): StatusCode {
    const { statusCode } = this.cosmos;
    console.log('status code: ', statusCode);
    return {
      SUCCESS: statusCode.SUCCESS,
      NOT_FOUND: statusCode.NOT_FOUND,
      GENERIC_ERROR: statusCode.GENERIC_ERROR,
    };
  }

  async getSignedData(data: string): Promise<SignedData | undefined> {
    const pathOrChildKey = await this.getChildKey();
    const childKey =
      typeof pathOrChildKey === 'string' ? await this.getChildKey(pathOrChildKey as string) : pathOrChildKey;
    if (!childKey) {
      return;
    }
    const hash = Buffer.from(sha256.digest(data));
    const signature = Buffer.from(this.cosmos.signRaw(hash, childKey.privateKey as Uint8Array));
    return {
      signature: signature.toString('base64'),
      publicKey: childKey.publicKey.toString('base64'),
    };
  }

  getHandleMessage(contract: string, msg: Buffer, sender: string, funds?: string, memo?: string): any {
    const sent_funds = funds ? [{ denom: this.cosmos.bech32MainPrefix, amount: funds }] : null;

    const msgSend = new message.cosmwasm.wasm.v1beta1.MsgExecuteContract({
      contract,
      msg,
      sender,
      sent_funds,
    });

    const msgSendAny = new message.google.protobuf.Any({
      type_url: '/cosmwasm.wasm.v1beta1.MsgExecuteContract',
      value: message.cosmwasm.wasm.v1beta1.MsgExecuteContract.encode(msgSend).finish(),
    });

    return new message.cosmos.tx.v1beta1.TxBody({
      messages: [msgSendAny],
      memo,
    });
  }

  getSendMessage(sender: string, memo: string, toAddress: string, amount: number): any {
    const msgSend = new message.cosmos.bank.v1beta1.MsgSend({
      from_address: sender,
      to_address: toAddress,
      amount: [{ denom: this.cosmos.bech32MainPrefix, amount: amount.toString() }],
    });

    const msgSendAny = new message.google.protobuf.Any({
      type_url: '/cosmos.bank.v1beta1.MsgSend',
      value: message.cosmos.bank.v1beta1.MsgSend.encode(msgSend).finish(),
    });

    return new message.cosmos.tx.v1beta1.TxBody({
      messages: [msgSendAny],
      memo,
    });
  }

  encodeTxBody(txBody: any): Uint8Array {
    return message.cosmos.tx.v1beta1.TxBody.encode(txBody).finish();
  }
}

// export default Wasm;
window.Wasm = new Wasm(process.env.REACT_APP_LCD ?? 'https://lcd.orai.io', process.env.REACT_APP_NETWORK);

if (!window.Wallet) {
  window.Wallet = new window.Keystation({
    keystationUrl: process.env.REACT_APP_WALLET_URL,
    lcd: process.env.REACT_APP_LCD,
  });
}
