import { AuthActionType, AuthContext } from 'providers/AuthProvider';
import React, { useContext } from 'react';
import '../index.scss';
import LocalStorage, { LocalStorageKey } from 'services/LocalStorage';

const LogoutButton: React.FC<{}> = React.memo(() => {
  const authCtx = useContext(AuthContext);

  const handleLogout = () => {
    LocalStorage.removeItem(LocalStorageKey.token);
    LocalStorage.removeItem(LocalStorageKey.childkey);
    authCtx.dispatch({ type: AuthActionType.Logout });
    window.location.reload();
  };

  return (
    <button className="logout-btn" onClick={handleLogout}>
      <span className="logout-txt">Logout</span>
    </button>
  );
});

export default LogoutButton;
