import { Button, Checkbox, Input, Popconfirm, Select, Table } from "antd";
import { Endpoint, EndpointParam, EndpointParams, EndpointType } from "entities/Entities";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { TableConfigs } from "../../../TableConfigs";
const { Option } = Select;

interface HeadersProps {
  endpoint: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint>>;
}

type DataSource = EndpointParam;

const Headers: React.FC<HeadersProps> = React.memo(({ setEndpoint, endpoint }) => {
  const prms: EndpointParams = !isEmpty(endpoint.params) ? {...endpoint.params} : {};
  const refs = React.useRef<any>([]);
  const tableConfigs = TableConfigs();
  const { EditableCell, EditableRow } = tableConfigs;
  const [ dataSource, setDataSource] = useState<DataSource[]>(prms.headers || []);
  
  useEffect(() => {
    setEndpoint({
      ...endpoint,
      params: {
        ...prms,
        headers: dataSource
      }
    });
  }, [dataSource])

  const handleChangeRequired = (e: any, record: DataSource) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, isRequired: e.target.checked };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const handleChangeType = (val: EndpointType, record: DataSource) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, type: val };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const handleBlurInput = (idx: string, record: DataSource) => {
    const val = refs.current[idx]?.input?.value || "";
    if (!val) return;

    const newData = [...dataSource];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const updated: EndpointParam = { ...item, name: val };
    newData.splice(index, 1, { ...updated });
    setDataSource(newData);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      render: (_: any, record: DataSource) => (
        <Input 
          ref={(el: any) => {refs.current[`name${record.id}`] = el}}
          onBlur={() => handleBlurInput(`name${record.id}`, record)}
          defaultValue={record.name}
        />
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '30%',
      render: (_: any, record: DataSource) => (
        <Select
          defaultValue={EndpointType.string}
          bordered={false}
          className="select_method"
          onChange={(val: EndpointType)=>handleChangeType(val, record)}
          value={record.type}
        >
          {Object.keys(EndpointType).map((m: string) => (
            <Option key={m} value={m}>
              {m}
            </Option>
          ))}
      </Select>
      )
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      render: (_: any, record: DataSource) => (
        <Checkbox onChange={(e) => handleChangeRequired(e, record)} checked={record.isRequired}/>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: DataSource) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataSource = {
      key: dataSource.length,
      id: dataSource.length,
      name: "",
      type: EndpointType.string,
      isRequired: true,
    };
    setDataSource([...dataSource, newData]);
  };

  const handleDelete = (key: any) => {
    setDataSource([...dataSource.filter((d: any) => d.key !== key)]);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
          className="btn"
        >
          Add a param
        </Button>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
    </>
  );
})

export default Headers;
