import React, { useState } from 'react';
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import './index.scss';
import Menu from './Menu';
import Dashboard from './dashboard';
import MyServices from './my-services';
import BoughtServices from './bought-services';
import Claim from './claim';
import { useQuery } from 'react-query';
import { fetchMyServices } from './hooks';

interface ServiceManagementProps {
  viewMode?: ViewModeSM;
}

export enum ViewModeSM {
  Dashboard,
  Claim,
  MyServices,
  BoughtServices,
}

const ServiceManagement: React.FC<ServiceManagementProps> = React.memo(({ viewMode = ViewModeSM.Dashboard }) => {
  const { isLoading, data: myServices } = useQuery<any>(['myServices'], () => fetchMyServices(), {
    keepPreviousData: true,
  });
  return (
    <div className="container service-management">
      <Menu viewMode={viewMode} myServices={myServices} />
      <div className="content-wrappper">
        {viewMode == ViewModeSM.Dashboard && <Dashboard />}
        {viewMode == ViewModeSM.Claim && <Claim />}
        {viewMode == ViewModeSM.MyServices && <MyServices />}
        {viewMode == ViewModeSM.BoughtServices && <BoughtServices />}
      </div>
    </div>
  );
});

export default ServiceManagement;
