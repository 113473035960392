import { Typography } from 'antd';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ConnectWalletButton from './connect-wallet/ConnectWalletButton';
import 'pages/Market/index.scss';
import './index.scss';
import { isLoggedIn } from 'providers/AuthProvider';

const { Text } = Typography;

const Header: React.FC<{}> = React.memo((props) => {
  const isLogin = isLoggedIn();
  const [link, setLink] = useState('/');

  const renderLink = (to: string, title: string, onClick: any) => {
    return (
      <Link className="hd-link" to={to} onClick={() => onClick(to)}>
        <Text className={'logo-text light ' + (link === to ? 'active' : '')}>{title}</Text>
        {link === to && <div className="bd-bottom" />}
      </Link>
    );
  };

  return (
    <div className="menu-bar">
      <div className="menu-items">
        <div className="menu">
          <Logo style={{ width: 40, height: 40 }} />
          <Link to={'/'} onClick={() => setLink('/')}>
            <Text className="logo-text">Oraichain AI Marketplace</Text>
          </Link>
        </div>
      </div>
      <div className="left-header">
        <div className="items">
          {renderLink('/market', 'Marketplace', setLink)}
          {isLogin && renderLink('/service-management', 'My services', setLink)}
        </div>
        <ConnectWalletButton />
      </div>
    </div>
  );
});

export default Header;
