import { Button, Form, Input, message, Typography } from 'antd';
import { ServiceConfig, ServiceEntity } from 'entities/Entities';
import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import '../index.scss';
// import { validateUrl } from 'utils/function';
import { changeServiceConfig } from '../../hooks';
// const { Text } = Typography;

interface BaseUrlProps {
  service: ServiceEntity;
}

const BaseUrl: FunctionComponent<BaseUrlProps> = ({ service }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    form.setFieldsValue({
      base_url: service.config?.base_url || '',
      token: service.config?.token || '',
    });
  }, [service]);

  const changeServiceConfigMutation = useMutation(changeServiceConfig, {
    onSuccess: (data: ServiceConfig) => {
      queryClient.refetchQueries('myServices', { active: true, exact: true });
      setLoading(false);
      setDisabled(true);
      message.success('Service added successfully');
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err.message);
    },
  });

  const handleSave = async (values: any) => {
    try {
      setLoading(true);
      const { base_url, token } = values;
      await changeServiceConfigMutation.mutateAsync({
        service_id: service.id,
        base_url,
        token,
        id: service.config?.id,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <>
      <Form form={form} className="base-url" layout="vertical" onFinish={handleSave} onFinishFailed={() => {}}>
        <Form.Item
          name="base_url"
          label="Base URL"
          rules={[
            {
              required: true,
              type: 'url',
            },
            // {
            //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
            //     !validateUrl(value) ? cb('Invalid Url') : cb();
            //   },
            // },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="token"
          label="Token"
          rules={[
            {
              type: 'string',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button className="btn" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default BaseUrl;
