import React, { useState } from 'react';
import { Typography, Upload, Input, Select, Button, Tabs, Modal, message, Form } from 'antd';
import 'antd/dist/antd.css';
import '../../index.scss';
import Info from './Info';
import ApiDefinition from './ApiDefinition';
import validation from './validation';
import { isEmpty } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { Endpoint, EndpointMethod, ServiceEntity } from 'entities/Entities';
import { changeEndpoint } from 'pages/ServiceManagement/hooks';
import TestEndpointForm from 'components/Form/TestEndpointForm';

interface DefinitionProps {
  onCancel: () => void;
  service: ServiceEntity,
  currentEndpoint?: Endpoint,
}

const ErrorsModal = ({onCancel, errors=[] }: any) => {
  return (
    <Modal visible={true} onCancel={onCancel} footer={null} width={500}>
      <div>Something went wrong</div>
      {errors.map((err: any, idx: any) => (
        <div key={idx}>* { err }</div>
      )) 
      }
    </Modal>
  );
}

const TestEnpointModal = ({service, endpoint, onCancel }: {service: ServiceEntity, endpoint: Endpoint, onCancel: ()=>void}) => {
  return (
    <Modal visible={true} onCancel={onCancel} footer={null} width={1000}>
      <TestEndpointForm
        service={service}
        endpoint={endpoint}
      />
    </Modal>
  );
}

//=======================
const Definition: React.FC<DefinitionProps> = React.memo(({ onCancel, service, currentEndpoint }) => {
  const defautlState: Endpoint = {
    name: "",
    description: "",
    instruction_link: "",
    method: EndpointMethod.GET,
    path: "",
    service_id: service.id,
    id: currentEndpoint?.id,
  }

  const queryClient = useQueryClient();
  const [endpoint, setEndpoint] = useState<any>(currentEndpoint || defautlState);
  const [errors, setErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openTestEndpoint, setOpenSetEndpoint] = useState(false);

  const changeEndpointMutation = useMutation(changeEndpoint, {
    onSuccess: (data: Endpoint) => {
      queryClient.refetchQueries(['service', { id: service?.id, isAuthen: true }], { active: true, exact: true });
      setLoading(false);
      setEndpoint(data);
      message.success('Successfully');
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err.message);
    },
  });

  const handleSave = async () => {
    const errors = validation(endpoint).errors;
    if (!isEmpty(errors)) {
      setErrors(errors);
      return;
    }
    
    try {
      setLoading(true);
      await changeEndpointMutation.mutateAsync(endpoint);
    } catch (err: any) {
      console.log(err);
    }
  }

  return (
    <>
      <div> 
        <Button className="btn" onClick={onCancel}>Endpoint list</Button>
        {endpoint.id && <Button className="btn btn_save-endpoint" onClick={()=>setOpenSetEndpoint(true)}>Test Endpoint</Button> }
      </div>
      <Info setEndpoint={setEndpoint} endpoint={endpoint}/>
      <div> 
        <Button className="btn" onClick={onCancel}>Cancel</Button>
        <Button className="btn btn_save-endpoint" onClick={handleSave} loading={loading}>Save</Button>
      </div>
      <ApiDefinition setEndpoint={setEndpoint} endpoint={endpoint}/>
      {!isEmpty(errors) && <ErrorsModal onCancel={()=>setErrors([])} errors={errors}/>}
      {openTestEndpoint && <TestEnpointModal service={service} endpoint={endpoint} onCancel={()=>setOpenSetEndpoint(false)}/>}
    </>
  );
});

export default Definition;
